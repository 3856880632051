import React from "react";
import { Link } from 'gatsby-plugin-react-i18next';

import MinimalLayout from "../components/layout/minimal";
import SEO from "../components/seo";
import Map from '../components/homeMap/map';
import styles from '../components/styles/map.module.scss';

const MapPage = () => {
    return (
        <MinimalLayout>
            <SEO title="Home" />
            <main className={styles.main}>
                <div className={styles.banner}>
                    <p>Help develop a feedback system that can prevent the spread and manage the impact of COVID19 in your area</p>
                    <Link to="/survey" className="btn btn-primary btn-sm font-weight-bold">
                        Get started
                    </Link>
                </div>
                <div className={styles.mapWrapper}>
                    <Map width="100%" height="100%" />
                </div>
            </main>
        </MinimalLayout>
    );
};

export default MapPage;
