import React, { useState } from 'react';
import ReactMapGL, { Layer, Source } from 'react-map-gl';

import { heatLayer, pointLayer } from './mapLayers';
import 'mapbox-gl/dist/mapbox-gl.css';

export default ({ width, height }) => {
    const [mapViewport, setMapViewport] = useState({
        latitude: 39.82,
        longitude: -98.52,
        zoom: 4,
    });

    const onViewportChange = (viewport) => {
        const { width, height, ...props } = viewport;
        setMapViewport(props);
    };

    return (
        <ReactMapGL 
            {...mapViewport}
            width={width}
            height={height}
            mapboxApiAccessToken={CONFIG.mapboxToken}
            mapStyle="mapbox://styles/mapbox/dark-v10"
            onViewportChange={onViewportChange}>
            <Source id="us-mobility" type="geojson" data={`/usmobility.geojson.gz`}>
                <Layer {...heatLayer} />
                <Layer {...pointLayer} />
            </Source>
        </ReactMapGL>
    );
};