export const heatLayer = {
    id: 'mobility-heat',
    type: 'heatmap',
    source: 'us_mobility',
    maxzoom: 15,
    paint: {
        // increase weight as diameter breast height increases
        'heatmap-weight': {
            property: 'Mobility Index',
            type: 'exponential',
            stops: [
                [0, 0],
                [5, 1]
            ]
        },
        // increase intensity as zoom level increases
        'heatmap-intensity': {
            stops: [
                [5, 1],
                [15, 2]
            ]
        },
        // assign color values be applied to points depending on their density
        'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(254,229,217,0)',
            0.2, 'rgb(252,174,145)',
            0.4, 'rgb(251,106,74)',
            0.6, 'rgb(222,45,38)',
            0.8, 'rgb(165,15,21)'
        ],
        // increase radius as zoom increases
        'heatmap-radius': {
            stops: [
                [5, 15],
                [15, 150]
            ]
        },
        // decrease opacity to transition into the circle layer
        'heatmap-opacity': {
            default: 1,
            stops: [
                [14, 1],
                [15, 0]
            ]
        },
    }
};

export const pointLayer = {
    id: 'mobility-point',
    type: 'circle',
    source: 'us_mobility',
    minzoom: 14,
    paint: {
        // increase the radius of the circle as the zoom level and Mobility Index value increases
        'circle-radius': {
            property: 'Mobility Index',
            type: 'exponential',
            stops: [
                [{ zoom: 15, value: 1 }, 5],
                [{ zoom: 15, value: 62 }, 10],
                [{ zoom: 22, value: 1 }, 20],
                [{ zoom: 22, value: 62 }, 50],
            ]
        },
        'circle-color': {
            property: 'Mobility Index',
            type: 'exponential',
            stops: [
                [0, 'rgba(236,222,239,0)'],
                [10, 'rgb(236,222,239)'],
                [20, 'rgb(208,209,230)'],
                [30, 'rgb(166,189,219)'],
                [40, 'rgb(103,169,207)'],
                [50, 'rgb(28,144,153)'],
                [60, 'rgb(1,108,89)']
            ]
        },
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': {
            stops: [
                [14, 0],
                [15, 1]
            ]
        }
    }
};